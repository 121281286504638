import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
  margin-top: 20vh;
`;
const Title = styled.div.attrs({
  children: "JKG"
})`
  font-size: 3rem;
  font-weight: 300;
  color: black;
`;
const SubTitle = styled.div.attrs({
  children: "consulting"
})`
  font-size: 1rem;
  font-weight: 300;
  color: black;
`;

const InfoContainer = styled.div`
  margin-top: 5vh;
`

class App extends Component {
  render() {
    return (
      <Container>
        <TitleContainer>
          <Title />
          <SubTitle />
        </TitleContainer>
        <InfoContainer>
          Creating clean custom solutions for your business's needs.          
        </InfoContainer>
        <InfoContainer>
          Contact today to get your project started          
        </InfoContainer>
        <InfoContainer style={{fontStyle: 'italic', fontWeight: 300}}>
          joshuakgriff@gmail.com        
        </InfoContainer>

      </Container>
    );
  }
}

export default App;
